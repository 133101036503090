//
export const userTypeListApi = '/external/user/user-type-list'
    // Region Name
export const regionList = '/cotton/config/regions/list'
export const regionStore = '/cotton/config/regions/store'
export const regionUpdate = '/cotton/config/regions/update'
export const regionStatus = '/cotton/config/regions/toggle-status'
export const regionDestroy = '/cotton/config/regions/destroy'
    // Zone Name
export const zoneList = '/cotton/config/zones/list'
export const zoneStore = '/cotton/config/zones/store'
export const zoneUpdate = '/cotton/config/zones/update'
export const zoneStatus = '/cotton/config/zones/toggle-status'
export const zoneDestroy = '/cotton/config/zones/destroy'
    // Cotton Variety
export const cottonVarietyList = '/cotton/config/cotton-variety/list'
export const cottonVarietyStore = '/cotton/config/cotton-variety/store'
export const cottonVarietyUpdate = '/cotton/config/cotton-variety/update'
export const cottonVarietyStatus = '/cotton/config/cotton-variety/toggle-status'
export const cottonVarietyDestroy = '/cotton/config/cotton-variety/destroy'
    // Cotton Name
export const cottonNameList = '/cotton/config/cotton-names/list'
export const cottonNameStore = '/cotton/config/cotton-names/store'
export const cottonNameUpdate = '/cotton/config/cotton-names/update'
export const cottonNameStatus = '/cotton/config/cotton-names/toggle-status'
export const cottonNameDestroy = '/cotton/config/cotton-names/destroy'
    // ha Manage
export const unitList = '/cotton/config/units/list'
export const unitStore = '/cotton/config/units/store'
export const unitUpdate = '/cotton/config/units/update'
export const unitStatus = '/cotton/config/units/toggle-status'
export const unitDestroy = '/cotton/config/units/destroy'
    // hat Manage
export const hatList = '/cotton/config/hats/list'
export const hatStore = '/cotton/config/hats/store'
export const hatUpdate = '/cotton/config/hats/update'
export const hatStatus = '/cotton/config/hats/toggle-status'
export const hatDestroy = '/cotton/config/hats/destroy'
    // ha Manage
export const hatManageList = '/cotton/config/hat-manage/list'
export const hatManageStore = '/cotton/config/hat-manage/store'
export const hatManageUpdate = '/cotton/config/hat-manage/update'
export const hatManageStatus = '/cotton/config/hat-manage/toggle-status'
export const hatManageDestroy = '/cotton/config/hat-manage/destroy'
    // seasions Name
export const seasionList = '/cotton/config/seasons/list'
export const seasionStore = '/cotton/config/seasons/store'
export const seasionUpdate = '/cotton/config/seasons/update'
export const seasionStatus = '/cotton/config/seasons/toggle-status'
export const seasionDestroy = '/cotton/config/seasons/destroy'
// report Heading api Routes
export const reportHeadingList = '/cotton/config/report-heading/list'
export const reportHeadingStore = '/cotton/config/report-heading/store'
export const reportHeadingUpdate = '/cotton/config/report-heading/update'
export const reportToggleStatus = '/cotton/config/report-heading/toggle-status'
// hat Manage
export const unitAssignList = '/cotton/config/unit-assign/list'
export const unitAssignStore = '/cotton/config/unit-assign/store'
