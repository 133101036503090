<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                  <b-form  @submit.prevent="handleSubmit(register)">
                    <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="org_id"
                          slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('globalTrans.organization')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                            plain
                            v-model="report_heading.org_id"
                            :options="orgList"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Heading (En)" vid="heading" rules="">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="scheme_type"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{$t('irrigation_config.heading')}}
                        </template>
                        <b-form-input
                          id="scheme_type"
                          v-model="report_heading.heading"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Heading (Bn)" vid="heading_bn" rules="">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="scheme_type"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{$t('irrigation_config.heading_bn')}}
                        </template>
                        <b-form-input
                          id="scheme_type"
                          v-model="report_heading.heading_bn"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Address" rules="" >
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('irrigation_config.address')"
                        label-for="address"
                        slot-scope="{ valid, errors }"
                      >
                        <b-form-textarea
                          id="address"
                          v-model="report_heading.address"
                          placeholder="Enter address"
                          rows="1"
                          max-rows="2"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-textarea>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Address Bn" vid="address_bn" rules=''>
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('irrigation_config.address_bn')"
                        label-for="address_bn"
                        slot-scope="{ valid, errors }"
                      >
                        <b-form-textarea
                          id="address_bn"
                          v-model="report_heading.address_bn"
                          placeholder="Enter address in Bangla"
                          rows="1"
                          max-rows="2"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-textarea>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Left Logo" vid="left_logo" rules="">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('irrigation_config.left_logo')"
                        label-for="left_logo"
                        slot-scope="{ valid, errors }"
                      >
                        <b-form-file
                          id="left_logo"
                          v-on:change="onLeftLogoChange"
                          accept="image/*"
                          v-model="report_heading.left_logo"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-file>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                        <small>{{ $t('cotton_config.image_file_instruction') }}</small>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Right Logo" vid="right_logo" rules="">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('irrigation_config.right_logo')"
                        label-for="right_logo"
                        slot-scope="{ valid, errors }"
                      >
                        <b-form-file
                          id="right_logo"
                          v-on:change="onRightLogoChange"
                          accept="image/*"
                          v-model="report_heading.right_logo"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-file>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                        <small>{{ $t('cotton_config.image_file_instruction') }}</small>
                      </b-form-group>
                    </ValidationProvider>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriMarketingServiceBaseUrl } from '@/config/api_config'
import { reportHeadingStore, reportHeadingUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getReportHeadingData()
      this.report_heading = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      report_heading: {
        heading: '',
        heading_bn: '',
        left_logo: [],
        right_logo: [],
        address: '',
        address_bn: '',
        created_by: 1,
        updated_by: 1
      },
      upload_left_logo: '',
      upload_right_logo: ''
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    }
  },
  methods: {
    onLeftLogoChange (e) {
      this.upload_left_logo = e.target.files[0]
    },
    onRightLogoChange (e) {
      this.upload_right_logo = e.target.files[0]
    },
    getReportHeadingData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: true }

      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      var formData = new FormData()
      Object.keys(this.report_heading).map(key => {
        if (key === 'left_logo') {
          formData.append(key, this.upload_left_logo)
        }
        if (key === 'right_logo') {
          formData.append(key, this.upload_right_logo)
        } else {
          formData.append(key, this.report_heading[key])
        }
      })

      if (this.report_heading.id) {
        formData.append('_method', 'PUT')
        result = await RestApi.postData(agriMarketingServiceBaseUrl, `${reportHeadingUpdate}/${this.id}`, formData, config)
      } else {
        result = await RestApi.postData(agriMarketingServiceBaseUrl, reportHeadingStore, formData, config)
      }
      this.$store.dispatch('mutateCommonProperties', loadinState)
      if (result.success) {
        this.$store.dispatch('agriMarketing/mutateAgriMarketingProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
